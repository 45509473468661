<template>
<v-flex xs12 sm12 md12 lg6 xl6>
  <h3>Overzicht van {{startDate}} tot en met {{endDate}}</h3>
    <v-list dense>
      <v-list-tile
        v-for="(item, index) in items"
        :key="index"
        :class="item.header !== undefined ? 'primary' : ''">
        <v-list-tile-content v-if="item.header !== undefined">
          <v-list-tile-title @click="reportCallback(item)">
            {{item.header}}
            <span class="right">{{ item.total }}u</span>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-content v-if="item.header === undefined">
          <v-list-tile-title class="pl-4">
            {{item.name}}
            <span class="right">{{ item.total }}u</span>
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-btn 
    class="error ml-0" 
    @click="$emit('done')">
        Sluiten
    </v-btn>
</v-flex>
</template>

<script>
export default {
  name: 'hourBookingOverview',
props: ['hourBookings', 'startDate', 'endDate'],
  data () {
    return {
      items: []
    }
  },
  methods: {
    reportCallback(item) {
      this.$emit('openReport', (item.id == undefined ? item.header : item.id))
    }
  },
  mounted() {
    if (this.hourBookings && this.hourBookings.length > 0) {
      var currentHeader = ''
      var currentTotal = 0
      var that = this
      this.hourBookings.forEach(function(item) {
        if (item.name !== currentHeader) {
          var index = that.items.findIndex(e => e.header !== undefined && e.header === currentHeader)
          if (index > -1) that.items[index].total = currentTotal
          that.items.push({'id': item.id, 'header': item.name, 'total': 0})
          currentHeader = item.name
          currentTotal = 0
        }
        that.items.push({'name': item.subName, 'total': item.total})
        currentTotal += item.total
      })
      var index = that.items.findIndex(e => e.header !== undefined && e.header === currentHeader)
      if (index > -1) that.items[index].total = currentTotal
    }
  }
}
</script>