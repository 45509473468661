<template>
  <v-layout wrap>
    <v-card v-if="!showReport && !showOverview">
      <v-card-text>
      <v-dialog
        ref="dialog1"
        v-model="modal1"
        :return-value.sync="startDate"
        width="290px"
        lazy>
        <v-text-field
          slot="activator"
          v-model="getStartDate"
          label="Van"
          readonly
        ></v-text-field>
        <v-date-picker 
          locale="nl-nl"
          v-model="startDate"
          :max="endDate"
          show-week
          full-width
          @input="$refs.dialog1.save(startDate)" 
          scrollable>
        </v-date-picker>
      </v-dialog>
      <v-dialog
        ref="dialog2"
        v-model="modal2"
        :return-value.sync="endDate"
        width="290px"
        lazy>
        <v-text-field
          slot="activator"
          v-model="getEndDate"
          label="Tot en met"
          readonly
        ></v-text-field>
        <v-date-picker 
          locale="nl-nl"
          v-model="endDate"
          :min="startDate"
          show-week
          full-width
          @input="$refs.dialog2.save(endDate)" 
          scrollable>
        </v-date-picker>
      </v-dialog>
      <v-autocomplete
        v-if="projects.length > 0 && startDate && endDate"
        :items="items"
        :item-text="getProjectDescription"
        return-object
        :search-input.sync="search"
        v-model="selectedProject"
        hide-no-data
        hide-details
        label="Selecteer een project"
      ></v-autocomplete>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          v-if="startDate != null && endDate != null && projects.length"
          class="primary"
          @click="getOverview">
          Totaal overzicht
        </v-btn>
      </v-card-actions>
    </v-card>
    <hourBookingReport 
      v-if="showReport" 
      :hourBookings="hourBookings" 
      :startDate="startDate"
      :endDate="endDate"
      :subject="selectedProject.name"
      :roles="roles"
      v-on:done="resetReport">
    </hourBookingReport>
    <hourBookingOverview
      v-if="showOverview" 
      :hourBookings="hourBookings"
      :startDate="startDate"
      :endDate="endDate"
      v-on:done="resetReport"
      v-on:openReport="openReport({$event})">
    </hourBookingOverview>
    <v-dialog v-model="dialog" max-width="300px">
      <v-alert
        :value="true"
        type="error"
      >
        {{dialogText}}
      </v-alert>      
    </v-dialog>
  </v-layout>
</template>

<script>
import hourBookingReport from '../../components/HourBookingReport.vue'
import hourBookingOverview from '../../components/HourBookingOverview.vue'
export default {
  name: 'Project',
  props: ['roles'],
  components: { 
    hourBookingReport,
    hourBookingOverview
  },
  data () {
    return {
      search: null,
      items: [],
      projects: [],
      showReport: false,
      showOverview: false,
      selectedProject: null,
      startDate: null,
      endDate: null,
      hourBookings: [],
      dialog: false,
      dialog1: false,
      dialog2: false,
      modal1: false,
      modal2: false,
      dialogText: ''
    }
  },
  watch: {
    search (val) {
      if (val == '') this.items = this.projects
      val && val !== this.select
    },
    selectedProject (val) {
      if (val !== null) {
        this.getReport()
      }
    },
    endDate () {
      if (this.startDate && this.endDate) this.getProjects()
    },
    startDate (val) {
      var date = new Date(val)
      date.setMonth(date.getMonth()+1)
      date.setDate(0)
      this.endDate = date.toISOString().substr(0, 10)
      if (this.startDate && this.endDate) this.getProjects()
    }   
  },
  computed: {
    getStartDate () {
      return this.$moment(this.startDate).format('dddd D MMMM')
    },
    getEndDate () {
      return this.$moment(this.endDate).format('dddd D MMMM')
    } 
  },
  methods: {
    resetReport: function() {
      this.selectedProject = null
      this.showReport = false
      this.showOverview = false
      this.hourBookings = []
    },
    getProjects () {
      this.projects = []
      this.selectedProject = null
      this.$http.get('api/project/getbyactivty/' + this.startDate + '-' + this.endDate)
        .then(request => {
          if (!request.data && request.data.length < 1) {
            this.dialogText = 'Geen resultaten gevonden!'
            this.dialog = true
          } else {
            if (request.data && request.data.length > 0) {
              var lastType = -1
              var that = this
              request.data.forEach(function(project) {
                if (project.type != lastType) {
                  var header = ''
                  if (project.type == 0) header = 'Administratief'
                  if (project.type == 1) header = 'Productontwikkeling'
                  if (project.type == 2) header = 'Project'
                  if (project.type == 3) header = 'Service'
                  that.projects.push({
                    'header':header
                  })
                  lastType = project.type
                }
                that.projects.push({
                  'id': project.id,
                  'name': project.name,
                  'organization': project.organization.name,
                })
              })
            }
            this.items = this.projects
          }           
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    openReport(event) {
      this.hourBookings = []
      this.showOverview = false
      this.showReport = true
      this.selectedProject = this.projects[this.projects.findIndex(e => e.id == event.$event)]
      this.getReport()
    },
    getReport () {      
      this.$http.get('api/report/R' + this.startDate + '-' + this.endDate + '-' + this.selectedProject.id)
        .then(request => {
          this.hourBookings = request.data
          if (!this.hourBookings || this.hourBookings.length < 1) {
            this.dialogText = 'Geen resultaten gevonden!'
            this.dialog = true
          } else {
            this.showReport = true
          }          
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    getOverview () {      
      this.$http.get('api/report/overview/R' + this.startDate + '-' + this.endDate)
        .then(request => {
          this.hourBookings = request.data
          if (!this.hourBookings || this.hourBookings.length < 1) {
            this.dialogText = 'Geen resultaten gevonden!'
            this.dialog = true
          } else {
            this.showOverview = true
          }          
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    getProjectDescription(project) {
      if (project != null && project != undefined && project.header == undefined) {
        return project.name + ' (' + project.organization + ')'
      }
    }
  },
  mounted: function () {
    var date = new Date()
    date.setDate(1)
    date.setMonth(date.getMonth()-1)
    this.startDate = date.toISOString().substr(0, 10)
  }
}
</script>